import React from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { Slika } from "../utils/Models"
import Dialog from "@material-ui/core/Dialog"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import MobileStepper from "@material-ui/core/MobileStepper"
import CloseIcon from "@material-ui/icons/Close"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import Typography from "@material-ui/core/Typography"

export interface ImagesViewPopupProps extends WithTranslation {
    images: Slika[]
    show: boolean
    showStep: number
    onHide: Function
}

export interface ImagesViewPopupState {
    activeStep: number
}

class ImagesViewPopup extends React.Component<ImagesViewPopupProps, ImagesViewPopupState> {
    constructor (props: ImagesViewPopupProps) {
        super(props)

        this.state = {
            activeStep: 0
        }
    }

    componentDidUpdate (prevProps: ImagesViewPopupProps) {
        if (this.props.showStep !== prevProps.showStep) {
            this.setState({
                activeStep: this.props.showStep
            })
        }
    }

    hidePopup = () => {
        this.props.onHide()
    }

    handleNext = () => {
        this.setActiveStep(this.state.activeStep + 1)
    };

    handleBack = () => {
        this.setActiveStep(this.state.activeStep - 1)
    };

    setActiveStep = (step: number) => {
        this.setState({
            activeStep: step
        })
    }

    render () {
        var maxSteps = this.props.images.length
        return (
            <Dialog open={this.props.show} onClose={this.hidePopup} fullScreen>
                <Grid container justify="flex-end" className="Images-view-close">
                    <Grid item xs="auto">
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<CloseIcon />}
                            onClick={this.hidePopup}
                        >
                            {this.props.t("Close")}
                        </Button >
                    </Grid>
                </Grid>
                <Container maxWidth="md" className="Images-view-container">
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        activeStep={this.state.activeStep}
                        nextButton={
                            <IconButton size="small" color="primary" onClick={this.handleNext} disabled={this.state.activeStep === maxSteps - 1}>
                                <KeyboardArrowRight />
                            </IconButton >
                        }
                        backButton={
                            <IconButton size="small" color="primary" onClick={this.handleBack} disabled={this.state.activeStep === 0}>
                                <KeyboardArrowLeft />
                            </IconButton >
                        }
                    />
                    <Grid container spacing={1} justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Box className="Content-center">
                                <img
                                    className="Images-view-img"
                                    src={this.props.images[this.state.activeStep].url}
                                    alt={this.props.images[this.state.activeStep].naslov}
                                >
                                </img>
                            </Box>
                        </Grid>
                        <Grid item xs="auto">
                            <Box className="Images-view-text">
                                <Typography>
                                    {this.props.images[this.state.activeStep].naslov}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
        )
    }
}

export default withTranslation()(ImagesViewPopup)
