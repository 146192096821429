import React from "react"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import AccomodationList from "../componets/AccomodationList"
import MapContainer from "../componets/MapContainer"
import SearchPopup from "../componets/SearchPopup"

export interface SearchPageProps {
    accommodationStore?: AccommodationStore
}

@inject("accommodationStore")
@observer
export default class SearchPage extends React.Component<SearchPageProps> {
    componentDidMount () {
        if (this.props.accommodationStore!.accomodationCount === 0) {
            this.props.accommodationStore!.showSearchPopup()
        }
        window.scrollTo(0, 0)
        document.title = "Najemi sobo - " + this.props.accommodationStore!.searchParams.place
    }

    render () {
        return (
            <Grid container>
                <Grid item xs={12} md={6}>
                    <AccomodationList />
                </Grid>
                <Hidden mdDown>
                    <Grid item xs={12} md={6}>
                        <MapContainer />
                    </Grid>
                </Hidden>
                <SearchPopup/>
            </Grid>
        )
    }
}
