import React from "react"
import { FirebaseInt } from "../utils/Firebase"
import { BackendInt } from "../utils/Backend"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import { withTranslation, WithTranslation } from "react-i18next"
import { EditReqData } from "../utils/Models"
import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"

export interface EditRequestPopupProps extends WithTranslation {
    accommodationStore?: AccommodationStore
    firebase: FirebaseInt | null
    backend: BackendInt | null
}

export interface EditRequestPopupState {
    step: number
    rnoId: string
    rnoIdError: boolean
    data: EditReqData
}

@inject("accommodationStore")
@observer
class EditRequestPopup extends React.Component<EditRequestPopupProps, EditRequestPopupState> {
    constructor (props: EditRequestPopupProps) {
        super(props)

        this.state = {
            step: 0,
            rnoId: "",
            rnoIdError: false,
            data: {
                name: "",
                address: "",
                email: ""
            }
        }
    }

    popupShown = () => {
        this.props.firebase!.logEvent("cms-edit-request")
    }

    hidePopup = () => {
        this.props.accommodationStore!.hideAccomodationEditReq()
        this.setState({
            step: 0,
            rnoId: "",
            rnoIdError: false,
            data: {
                name: "",
                address: "",
                email: ""
            }
        })
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            rnoId: event.target.value,
            rnoIdError: false
        })
    }

    sendRequest = () => {
        if (this.state.rnoId !== "") {
            this.props.backend!.getAccomodationEditRequest(this.state.rnoId, false, (data: EditReqData) => {
                if (data === null) {
                    this.setState({
                        rnoIdError: true
                    })
                } else {
                    this.setState({
                        step: 1,
                        rnoIdError: false,
                        data: data
                    })
                }
            })
        }
    }

    sendAccess = () => {
        this.setState({
            step: 2
        })

        this.props.firebase!.logEvent("cms-edit-request-submit", { "rno-id": this.state.rnoId })

        this.props.backend!.getAccomodationEditRequest(this.state.rnoId, true, (data: EditReqData) => {})
    }

    getStepContent (step: number) {
        switch (step) {
        case 0:
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            size="small"
                            label={this.props.t("Enter RNO ID")}
                            error={this.state.rnoIdError}
                            helperText={this.state.rnoIdError ? this.props.t("Wrong RNO ID") : ""}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={this.handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} className="Content-right">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.sendRequest}
                        >
                            {this.props.t("Next")}
                        </Button>
                    </Grid>
                </Grid>
            )
        case 1:
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            {this.props.t("RNO ID")}: <b>{this.state.rnoId}</b>
                        </Typography>
                        <Typography>
                            {this.props.t("Name")}: <b>{this.state.data.name}</b>
                        </Typography>
                        <Typography>
                            {this.props.t("Address")}: <b>{this.state.data.address}</b>
                        </Typography>
                    </Grid>
                    {this.state.data.email === null
                        ? <Grid item xs={12}>
                            <Typography>
                                {this.props.t("No email")}
                            </Typography>
                            <Link href="https://www.ajpes.si/Registri/Drugi_registri/Register_nastanitvenih_obratov/Splosno">{this.props.t("Add here")}</Link>
                        </Grid>
                        : <Grid item xs={12}>
                            <Typography>
                                {this.props.t("Email")}: <b>{this.state.data.email}</b>
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12} className="Content-right">
                        {this.state.data.email === null
                            ? <Button
                                variant="outlined"
                                color="primary"
                                onClick={this.hidePopup}
                            >
                                {this.props.t("Close")}
                            </Button>
                            : <Button
                                variant="contained"
                                color="primary"
                                onClick={this.sendAccess}
                            >
                                {this.props.t("Send access")}
                            </Button>
                        }

                    </Grid>
                </Grid>
            )
        case 2:
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            {this.props.t("Access link was send to your email address.")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="Content-right">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.hidePopup}
                        >
                            {this.props.t("Close")}
                        </Button>
                    </Grid>
                </Grid>
            )
        }
    }

    render () {
        const { step } = this.state

        return (
            <Dialog open={this.props.accommodationStore!.accomodationEditReq} onClose={this.hidePopup} maxWidth="xs" onEntered={this.popupShown} fullWidth={true}>
                <DialogTitle>
                    {this.props.t("Edit your accomodation")}
                </DialogTitle>
                <DialogContent dividers={true}>
                    {this.getStepContent(step)}
                </DialogContent>
            </Dialog>
        )
    }
}

export default withTranslation()(EditRequestPopup)
