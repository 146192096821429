import React from "react"
import moment from "moment"
import { mapApiKey, widgetUrl } from "../utils/Config"
import {
    RouteComponentProps,
    withRouter
} from "react-router-dom"
import { withTranslation, WithTranslation } from "react-i18next"
import { FirebaseInt } from "../utils/Firebase"
import { BackendContext } from "../utils/Backend"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import { additionalOfferIcons } from "../utils/Data"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import SvgIcon from "@material-ui/core/SvgIcon"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import DetailImages from "../componets/DetailImages"
import HomeIcon from "@material-ui/icons/Home"
import Semaforum from "../componets/Semaforum"
import BookRequest from "../componets/BookRequest"
import { FacebookShareButton, FacebookIcon } from "react-share"
import GoogleMapReact from "google-map-react"
import MapMarker from "../componets/MapMarker"

interface ParamTypes {
    detailId: string
}

export interface DetailPageProps extends WithTranslation, RouteComponentProps<ParamTypes> {
    accommodationStore?: AccommodationStore
    firebase: FirebaseInt | null
}

@inject("accommodationStore")
@observer
class DetailPage extends React.Component<DetailPageProps> {
    private bookingRef = React.createRef<HTMLDivElement>()

    getParams () {
        const search = this.props.location.search
        const params = new URLSearchParams(search)

        var pFrom = params.get("from")
        var pTo = params.get("to")
        var pGuests = params.get("guests")

        if (pFrom) {
            this.props.accommodationStore!.setSearchParams({
                checkIn: moment(pFrom).toDate()
            })
        }

        if (pTo) {
            this.props.accommodationStore!.setSearchParams({
                checkOut: moment(pTo).toDate()
            })
        }

        if (pGuests) {
            this.props.accommodationStore!.setSearchParams({
                guests: parseInt(pGuests)
            })
        }
    }

    componentDidMount () {
        this.getParams()

        var rnoId = this.props.match.params.detailId

        this.props.firebase!.logEvent("detail-view", { "rno-id": rnoId })

        this.props.accommodationStore!.getAccomodation(rnoId, () => {
            window.scrollTo(0, 0)
            document.title = "Najemi sobo - " + this.props.accommodationStore!.accomodation?.nazivNastanitvenegaObrata
        })
    }

    address = () => {
        var addr = this.props.accommodationStore!.accomodation?.naslov
        if (addr) {
            return `${addr.nazivUlice} ${addr.hisnaStevilka}${addr.hisnaStevilkaDodatek}, ${addr.nazivPoste}`
        }
        return ""
    }

    bedsAndUnits = () => {
        if (this.props.accommodationStore!.accomodation) {
            var units: string[] = []
            this.props.accommodationStore!.accomodation!.nedeljiveEnote.forEach((unit: any) => {
                units.push(`${unit.kolicina}x ${unit.vrsta}`)
            })

            return (
                <Grid container className="Detail-container-section">
                    <Grid item xs={4}>
                        <Typography variant="body2" color="textSecondary">
                            {this.props.t("Units")}:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">
                            {units.join(", ")}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body2" color="textSecondary">
                            {this.props.t("Number of units")}:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">
                            {this.props.accommodationStore!.accomodation?.skupnoSteviloNedeljivihEnot}x
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body2" color="textSecondary">
                            {this.props.t("Number of beds")}:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">
                            {this.props.accommodationStore!.accomodation?.steviloStalnihLezisc}x
                        </Typography>
                    </Grid>
                </Grid>
            )
        }
    }

    additionalOffers = () => {
        if (this.props.accommodationStore!.accomodation) {
            return (
                <Grid container spacing={2} className="Detail-container-section">
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {this.props.t("Additional offers")}
                        </Typography>
                    </Grid>
                    {this.props.accommodationStore!.accomodation!.dodatnaPonudba.map((offer, idx) => (
                        <Grid item xs={12} sm={6} key={idx}>
                            <Grid container spacing={2}>
                                <Grid item xs="auto">
                                    <SvgIcon fontSize="small">
                                        <path d={additionalOfferIcons[offer]} />
                                    </SvgIcon>
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography variant="body1">
                                        {this.props.t(offer)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            )
        }
    }

    description = () => {
        if (this.props.accommodationStore!.accomodation && this.props.accommodationStore!.accomodation.podatkiNastanitve) {
            return (
                <Grid container spacing={1} className="Detail-container-section">
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            paragraph
                            dangerouslySetInnerHTML={{
                                __html: this.props.accommodationStore!.accomodation !== null && this.props.accommodationStore!.accomodation.podatkiNastanitve ? this.props.accommodationStore!.accomodation!.podatkiNastanitve.opis : ""
                            }}
                        >
                        </Typography>
                    </Grid>
                </Grid>
            )
        }
    }

    extraOffers = () => {
        if (this.props.accommodationStore!.accomodation && this.props.accommodationStore!.accomodation!.drugaPonudba.length > 0) {
            return (
                <Grid container spacing={2} className="Detail-container-section">
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {this.props.t("Extra offers")}
                        </Typography>
                    </Grid>
                    {this.props.accommodationStore!.accomodation!.drugaPonudba.map((offer, idx) => (
                        <Grid item xs={12} key={idx}>
                            <Typography variant="body1" display="inline">
                                {offer.naziv.replace(/^\w/, (c) => c.toUpperCase())}&nbsp;
                            </Typography>
                            <Typography variant="body2" display="inline" color="textSecondary">
                                ({offer.opis})
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            )
        }
    }

    location = () => {
        if (this.props.accommodationStore!.accomodation) {
            return (
                <Grid container spacing={2} className="Detail-container-section">
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {this.props.t("Location")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            {this.address()}
                        </Typography>
                    </Grid>
                    {this.props.accommodationStore!.accomodation.naslov.x && this.props.accommodationStore!.accomodation.naslov.y &&
                    <Grid item xs={12} className="Detail-map-container">
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: mapApiKey,
                                language: this.props.i18n.language
                            }}
                            options={{
                                disableDoubleClickZoom: true
                            }}
                            defaultZoom={13}
                            hoverDistance={20}
                            center={{
                                lat: this.props.accommodationStore!.accomodation.naslov.y,
                                lng: this.props.accommodationStore!.accomodation.naslov.x
                            }}
                        >
                            <MapMarker
                                idx={0}
                                label={<HomeIcon className="Map-marker-icon" fontSize="small"/>}
                                lat={this.props.accommodationStore!.accomodation.naslov.y}
                                lng={this.props.accommodationStore!.accomodation.naslov.x}
                                selected={false}
                                showPopover={() => {}}
                            />
                        </GoogleMapReact>
                    </Grid>
                    }
                </Grid>
            )
        }
    }

    share = () => {
        if (this.props.accommodationStore!.accomodation) {
            return (
                <Grid container spacing={1} className="Detail-container-section">
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {this.props.t("Share")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs="auto">
                                <Semaforum
                                    heading={this.props.accommodationStore!.accomodation?.nazivNastanitvenegaObrata || ""}
                                    price={this.totalPrice()}
                                    region={parseInt(this.props.accommodationStore!.accomodation?.naslov.postnaStevilka || "1000")}
                                    location={this.address()}
                                    keywords={["Slovenija", this.props.accommodationStore!.accomodation?.nazivKategorije || ""]}
                                    dateStart={this.props.accommodationStore!.searchParams.checkIn}
                                    dateEnd={this.props.accommodationStore!.searchParams.checkOut}
                                    url={window.location.href}
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <FacebookShareButton
                                    url={window.location.href}
                                >
                                    <FacebookIcon size={34} round={true}/>
                                </FacebookShareButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    }

    pricePerNight = () => {
        var nData = this.props.accommodationStore!.accomodation?.podatkiNastanitve
        if (nData) {
            if (nData.cenaOd === nData.cenaDo) {
                return `${nData.cenaOd}€ /${this.props.t("Night")}`
            } else {
                return `${nData.cenaOd}€ - ${nData.cenaDo}€ /${this.props.t("Night")}`
            }
        } else {
            return ""
        }
    }

    totalPrice = () => {
        var nData = this.props.accommodationStore!.accomodation?.podatkiNastanitve
        if (nData) {
            var nights = this.props.accommodationStore!.nights
            if (nData.cenaOd === nData.cenaDo) {
                var total = nData.cenaOd * nights
                return `${total}€ ${this.props.t("Total")}`
            } else {
                var totalFrom = nData.cenaOd * nights
                var totalTo = nData.cenaDo * nights
                return `${totalFrom}€ - ${totalTo}€ ${this.props.t("Total")}`
            }
        } else {
            return ""
        }
    }

    getWidgetUrl = () => {
        const params = new URLSearchParams({
            rno: this.props.accommodationStore!.accomodation!.rnoId,
            channel: "NS",
            color: "0874D8",
            lang: this.props.i18n.language,
            from: moment(this.props.accommodationStore!.searchParams.checkIn).format("Y-MM-DD"),
            to: moment(this.props.accommodationStore!.searchParams.checkOut).format("Y-MM-DD"),
            guests: this.props.accommodationStore!.searchParams.guests.toString()
        }).toString()

        return widgetUrl + "?" + params
    }

    render () {
        return (
            <Container maxWidth="lg" className="Detail-container">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {this.props.accommodationStore!.accomodation?.nazivKategorije}
                                        </Typography>
                                        <Typography variant="h5">
                                            {this.props.accommodationStore!.accomodation?.nazivNastanitvenegaObrata}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {this.address()}
                                        </Typography>
                                    </Grid>
                                    <Hidden mdUp>
                                        <Grid item xs={12} sm="auto">
                                            <Button
                                                variant="contained"
                                                color={ this.props.accommodationStore!.accomodation?.rezervacija ? "primary" : "secondary"}
                                                onClick={() => {
                                                    const node = this.bookingRef.current
                                                    node!.scrollIntoView()
                                                }}
                                            >
                                                { this.props.accommodationStore!.accomodation?.rezervacija ? this.props.t("Book") : this.props.t("Send inquiry")}
                                            </Button>
                                        </Grid>
                                    </Hidden>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    {this.props.accommodationStore!.accomodation !== null &&
                    this.props.accommodationStore!.accomodation.podatkiNastanitve &&
                    this.props.accommodationStore!.accomodation.podatkiNastanitve.slike &&
                    this.props.accommodationStore!.accomodation.podatkiNastanitve.slike.length > 0 &&
                        <Grid item xs={12}>
                            <DetailImages images={this.props.accommodationStore!.accomodation!.podatkiNastanitve.slike}/>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                {this.bedsAndUnits()}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.additionalOffers()}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.description()}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.extraOffers()}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.location()}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.share()}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6} ref={this.bookingRef}>
                                {this.props.accommodationStore!.accomodation !== null && !this.props.accommodationStore!.accomodation.rezervacija &&
                                    <Card variant="outlined">
                                        <CardContent>
                                            <BackendContext.Consumer>
                                                {backend =>
                                                    <BookRequest
                                                        firebase={this.props.firebase}
                                                        backend={backend}
                                                        rnoId={this.props.accommodationStore!.accomodation!.rnoId}
                                                        pricePerNight={this.pricePerNight()}
                                                        totalPrice={this.totalPrice()}
                                                    />
                                                }
                                            </BackendContext.Consumer>
                                        </CardContent>
                                    </Card>
                                }
                                {this.props.accommodationStore!.accomodation !== null && this.props.accommodationStore!.accomodation.rezervacija &&
                                    <iframe
                                        title="Booking widget"
                                        className="Detail-widget"
                                        src={this.getWidgetUrl()} >
                                    </iframe>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}></Grid>
                </Grid>
            </Container>
        )
    }
}

export default withTranslation()(withRouter(DetailPage))
