import React from "react"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import { withTranslation, WithTranslation } from "react-i18next"
import { FacebookShareButton, FacebookIcon } from "react-share"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Link from "@material-ui/core/Link"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/Add"

interface HeaderProps extends WithTranslation {
    accommodationStore?: AccommodationStore
}

interface HeaderState {
    language: string;
}

@inject("accommodationStore")
@observer
class Header extends React.Component<HeaderProps, HeaderState> {
    constructor (props: HeaderProps) {
        super(props)

        this.state = {
            language: "sl"
        }
    }

    changeLanguage = (event: any) => {
        this.setState({
            language: event.target.value
        }, () => {
            this.props.i18n.changeLanguage(this.state.language)
        })
    }

    showEditReqPopup = () => {
        this.props.accommodationStore!.showAccomodationEditReq()
    }

    render () {
        return (
            <Grid container>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Link href="#" color="inherit">
                                <img alt="Najemi sobo" src={require("../images/logo.svg")} className="App-header-logo" />
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={2} justify="flex-end">
                        <Grid item xs="auto">
                            <FacebookShareButton
                                url={"https://najemi-sobo.si"}
                            >
                                <FacebookIcon size={34} round={true}/>
                            </FacebookShareButton>
                        </Grid>
                        <Grid item xs="auto">
                            <Select
                                id="language-select"
                                value={this.state.language}
                                onChange={this.changeLanguage}
                            >
                                <MenuItem value="sl">
                                    <img alt="Slovenščina" src={require("../images/slovenia.svg")} className="App-header-language" />
                                </MenuItem>
                                <MenuItem value="en">
                                    <img alt="English" src={require("../images/united-kingdom.svg")} className="App-header-language" />
                                </MenuItem>
                            </Select>
                        </Grid>
                        <Hidden only="xs">
                            <Grid item xs="auto">
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    onClick={this.showEditReqPopup}
                                    startIcon={<AddIcon />}
                                >
                                    {this.props.t("Edit your accomodation")}
                                </Button>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(Header)
