import * as React from "react"
import { Redirect, Route, RouteProps } from "react-router"

export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: Function;
  authenticationPath: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
    let redirectPath = ""
    if (!props.isAuthenticated(props)) {
        redirectPath = props.authenticationPath
    }

    if (redirectPath) {
        return <Route {...props} > <Redirect to={{ pathname: redirectPath }} /> </Route>
    } else {
        return <Route {...props} />
    }
}

export default ProtectedRoute
