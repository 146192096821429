import React from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { Slika } from "../utils/Models"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Fab from "@material-ui/core/Fab"
import IconButton from "@material-ui/core/IconButton"
import TextField from "@material-ui/core/TextField"
import DeleteIcon from "@material-ui/icons/Delete"
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto"
import CardActionArea from "@material-ui/core/CardActionArea"
import Resizer from "react-image-file-resizer"

interface PhotoUploaderProps extends WithTranslation {
    photos: Slika[]
    onChange: Function
}

interface PhotoUploaderState {
    photosRaw: PhotoRaw[]
}

export interface PhotoRaw {
    img: File | null
    url: string
    title: string
}

class PhotoUploader extends React.Component<PhotoUploaderProps, PhotoUploaderState> {
    constructor (props: PhotoUploaderProps) {
        super(props)

        this.state = {
            photosRaw: []
        }
    }

    componentDidUpdate (prevProps: PhotoUploaderProps) {
        if (this.props.photos !== prevProps.photos) {
            var photosRaw: PhotoRaw[] = []
            this.props.photos.forEach((photo: Slika) => {
                photosRaw.push({
                    img: null,
                    url: photo.url,
                    title: photo.naslov
                })
            })
            this.setState({
                photosRaw: photosRaw
            })
            this.props.onChange(photosRaw)
        }
    }

    imgValid = (img: File) => {
        return img.type === "image/jpeg" && img.size < (20 * 1024 * 1024)
    }

    setImage = (img: File, idx: number | null) => {
        if (this.imgValid(img)) {
            Resizer.imageFileResizer(
                img,
                800,
                600,
                "JPEG",
                80,
                0,
                (blob: string | Blob | ProgressEvent<FileReader>) => {
                    if (!(blob instanceof Blob)) {
                        return
                    }

                    var photos = this.state.photosRaw

                    if (idx) {
                        photos[idx].img = new File([blob], img.name, { type: "image/jpeg" })
                        photos[idx].url = ""
                    } else {
                        photos.push({
                            img: new File([blob], img.name, { type: "image/jpeg" }),
                            url: "",
                            title: ""
                        })
                    }

                    this.setState({
                        photosRaw: photos
                    })

                    this.props.onChange(photos)
                },
                "blob"
            )
        }
    }

    render () {
        return (
            <Grid container spacing={4}>
                {this.state.photosRaw.map((photoRaw, idx) => (
                    <Grid item xs={12} sm={6} md={4} key={idx}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <input
                                    id={"replace-image-" + idx}
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(event: any) => { this.setImage(event.target.files[0], idx) }}
                                />
                                <CardActionArea>
                                    <label htmlFor={"replace-image-" + idx} style={{ cursor: "pointer" }}>
                                        <Box className="Photo-uploader-img-wrapper">
                                            <img src={photoRaw.img === null ? photoRaw.url : URL.createObjectURL(photoRaw.img)} alt={photoRaw.title} className="Photo-uploader-img"></img>
                                        </Box>
                                    </label>
                                </CardActionArea>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={photoRaw.title}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        var photos = this.state.photosRaw
                                        photos[idx].title = event.target.value

                                        this.setState({
                                            photosRaw: photos
                                        })
                                        this.props.onChange(photos)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} className="Content-right">
                                <Fab
                                    size="small"
                                    color="secondary"
                                    onClick={ () => {
                                        var photos = this.state.photosRaw
                                        photos.splice(idx, 1)
                                        this.setState({
                                            photosRaw: photos
                                        })
                                    }}
                                >
                                    <DeleteIcon />
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container justify="center" alignItems="center" className="Photo-uploader-upload">
                        <Grid item xs="auto">
                            <IconButton
                                component="label"
                            >
                                <AddAPhotoIcon fontSize="large" />
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(event: any) => { this.setImage(event.target.files[0], null) }}
                                />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(PhotoUploader)
