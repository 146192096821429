const backendUrl = (process.env.NODE_ENV === "development" ? "http://localhost:8101/v2/site/" : "https://api.snapguest.pro/v2/site/")

const widgetUrl = "https://api.snapguest.pro/widget/"

const mapApiKey = "AIzaSyCG6a3mvcJ7zWwItVxrCKUNsLtWKU5EV3M"

const firebaseConfig = {
    apiKey: "AIzaSyC4rZUYV7jyN4ZyNNMBv47q2sxFk7n0TcY",
    authDomain: "snapguest-booking-site.firebaseapp.com",
    databaseURL: "https://snapguest-booking-site.firebaseio.com",
    projectId: "snapguest-booking-site",
    storageBucket: "snapguest-booking-site.appspot.com",
    messagingSenderId: "928870120264",
    appId: "1:928870120264:web:f430fc1e558b61baf8917d",
    measurementId: "G-1HW9VK19DV"
}

export { backendUrl, widgetUrl, mapApiKey, firebaseConfig }
