import React from "react"
import moment from "moment"
import { mapApiKey } from "../utils/Config"
import { withTranslation, WithTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import { Obrat } from "../utils/Models"
import Box from "@material-ui/core/Box"
import Popover from "@material-ui/core/Popover"
import Typography from "@material-ui/core/Typography"
import GoogleMapReact from "google-map-react"
import MapMarker from "./MapMarker"

interface MapContainerProps extends WithTranslation {
    accommodationStore?: AccommodationStore
}

interface MapContainerState {
    accomodation: Obrat | null
    url: string
    showPopover: boolean
    anchorEl: Element | null
}

@inject("accommodationStore")
@observer
class MapContainer extends React.Component<MapContainerProps, MapContainerState> {
    constructor (props: MapContainerProps) {
        super(props)

        this.state = {
            accomodation: null,
            url: "",
            showPopover: false,
            anchorEl: null
        }
    }

    onMarkerEnter = (key: string) => {
        this.props.accommodationStore!.accomodationMarked = parseInt(key)
    }

    onMarkerLeave = (key: string) => {
        this.props.accommodationStore!.accomodationMarked = -1
    }

    showPopover = (idx: number, event: React.MouseEvent<HTMLButtonElement>) => {
        var accomodation = this.props.accommodationStore!.accomodationList[idx]

        var params = this.props.accommodationStore!.searchParams
        var from = moment(params.checkIn).format(moment.HTML5_FMT.DATE)
        var to = moment(params.checkOut).format(moment.HTML5_FMT.DATE)

        this.setState({
            accomodation: accomodation,
            url: `/detail/${accomodation.rnoId}?from=${from}&to=${to}&guests=${params.guests}`,
            showPopover: true,
            anchorEl: event.currentTarget
        })
    }

    hidePopover = () => {
        this.setState({
            showPopover: false,
            anchorEl: null
        })
    }

    render () {
        return (
            <Box className="Map-container" >
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: mapApiKey,
                        language: this.props.i18n.language
                    }}
                    defaultZoom={13}
                    hoverDistance={20}
                    onChildMouseEnter={this.onMarkerEnter}
                    onChildMouseLeave={this.onMarkerLeave}
                    center={{
                        lat: this.props.accommodationStore!.middleCoordinates.y,
                        lng: this.props.accommodationStore!.middleCoordinates.x
                    }}
                >
                    {this.props.accommodationStore!.accomodationMarkers.map((marker, idx) => (
                        <MapMarker
                            key={idx}
                            idx={idx}
                            label={marker.label}
                            lat={marker.y}
                            lng={marker.x}
                            selected={this.props.accommodationStore!.accomodationMarked === idx}
                            showPopover={this.showPopover}
                        />
                    ))}
                </GoogleMapReact>
                <Popover
                    open={this.state.showPopover}
                    anchorEl={this.state.anchorEl}
                    onClose={this.hidePopover}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                >
                    <Link to={this.state.url} className="Link">
                        <Typography variant="body2" color="textSecondary">{this.state.accomodation?.nazivKategorije}</Typography>
                        <Typography variant="subtitle1">{this.state.accomodation?.nazivNastanitvenegaObrata}</Typography>
                        { this.state.accomodation?.podatkiNastanitve &&
                            <Box>
                                <img
                                    src={this.state.accomodation?.podatkiNastanitve.slike[0].url}
                                    alt={this.state.accomodation?.podatkiNastanitve.slike[0].naslov}
                                    className="Map-popover-image-img">
                                </img>
                            </Box>
                        }
                    </Link>
                </Popover>
            </Box>
        )
    }
}

export default withTranslation()(MapContainer)
