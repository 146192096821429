import React from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import Link from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

const activities = [
    {
        imgPath: "https://cdn.regiondo.net/media/catalog/product/cache/4/image/600x400/8a02aedcaf38ad3a98187ab0a1dede95/b/i/big-ticket-image-5eea12458aa02264521473-cropped600-400-dpl-5f1163c8150d1/best-canyoning-in-lake-bled-najemi-sobo-32ba4.jpg",
        url: "https://outdoor-slovenia.regiondo.com/best-canyoning-in-lake-bled-najemi-sobo",
        name: "Best Canyoning in Lake Bled",
        location: "Bled"
    },
    {
        imgPath: "https://cdn.regiondo.net/media/catalog/product/cache/4/image/600x400/8a02aedcaf38ad3a98187ab0a1dede95/b/i/big-ticket-image-5ef39a325f8fe994175166-cropped600-400-dpl-5f1163beb4a92/adventure-trip-kranjska-gora-bovec-bohinj-from-bled-najemi-sobo-3a305.jpg",
        url: "https://outdoor-slovenia.regiondo.com/adventure-trip-kranjska-gora-bovec-bohinj-from-bled-najemi-sobo",
        name: "Adventure Trip",
        location: "Bohinj"
    },
    {
        imgPath: "https://cdn.regiondo.net/media/catalog/product/cache/4/image/600x400/8a02aedcaf38ad3a98187ab0a1dede95/b/i/big-ticket-image-5eba921871a45772567122-cropped600-400-dpl-5f11647d0150b/rafting-in-bled-slovenia-najemi-sobo-3a7be.jpg",
        url: "https://outdoor-slovenia.regiondo.com/rafting-in-bled-slovenia-najemi-sobo",
        name: "Rafting in Bled Slovenia",
        location: "Bled"
    },
    {
        imgPath: "https://cdn.regiondo.net/media/catalog/product/cache/4/image/600x400/8a02aedcaf38ad3a98187ab0a1dede95/t/i/ticket-image-58c118d978a93711224468-cropped600-400-dpl-5f1163b371c3f/canyoning--rafting-in-bled-najemi-sobo-3e5d4.jpg",
        url: "https://outdoor-slovenia.regiondo.com/canyoning-rafting-in-bled-najemi-sobo",
        name: "Canyoning & Rafting in Bled",
        location: "Bled"
    },
    {
        imgPath: "https://cdn.regiondo.net/media/catalog/product/cache/4/image/600x400/8a02aedcaf38ad3a98187ab0a1dede95/b/i/big-ticket-image-5f0887a512445259759972-cropped600-400-dpl-5f11630d723f8/lake-bled-hot-air-balloon-flights-najemi-sobo-3ec77.jpg",
        url: "https://outdoor-slovenia.regiondo.com/lake-bled-hot-air-balloon-flights-najemi-sobo",
        name: "Lake Bled Hot Air Balloon Flights",
        location: "Bled"
    },
    {
        imgPath: "https://cdn.regiondo.net/media/catalog/product/cache/4/image/600x400/8a02aedcaf38ad3a98187ab0a1dede95/b/i/big-ticket-image-5f08563a74eb7885222268-cropped600-400-dpl-5f1162b5486ec/paragliding-over-lake-bled-najemi-sobo-31daf.jpg",
        url: "https://outdoor-slovenia.regiondo.com/paragliding-over-lake-bled-najemi-sobo",
        name: "Paragliding Over Lake Bled",
        location: "Bled"
    },
    {
        imgPath: "https://cdn.regiondo.net/media/catalog/product/cache/4/image/600x400/8a02aedcaf38ad3a98187ab0a1dede95/b/i/big-ticket-image-5ef1f529e3b80150672643-cropped600-400-dpl-5f11638970823/canyoning-zipline-bled-3dae2.jpg",
        url: "https://outdoor-slovenia.regiondo.com/canyoning-zipline-bled-najemi-sobo",
        name: "Canyoning Zipline Bled",
        location: "Bled"
    },
    {
        imgPath: "https://cdn.regiondo.net/media/catalog/product/cache/4/image/600x400/8a02aedcaf38ad3a98187ab0a1dede95/b/i/big-ticket-image-5af474b4da918145979719-cropped600-400-dpl-5f116396dc6e3/bled-sit-on-top-kajak-trip-31436.jpg",
        url: "https://outdoor-slovenia.regiondo.com/bled-sit-on-top-kajak-trip-najemi-sobo",
        name: "Bled Sit On Top Kajak Trip",
        location: "Bled"
    }
]

class Activities extends React.Component<WithTranslation> {
    showActivity = (url: string) => {
        window.location.href = url
    }

    render () {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        {this.props.t("Activities")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={6}>
                        {activities.map((activity, idx) => (
                            <Grid item key={idx} xs={12} sm={6} md={3}>
                                <Card variant="outlined">
                                    <CardActionArea onClick={() => { this.showActivity(activity.url) }}>
                                        <CardMedia
                                            component="img"
                                            height={200}
                                            image={activity.imgPath}
                                            title={activity.name}
                                        />
                                        <CardContent>
                                            <Typography variant="subtitle2">
                                                {activity.name}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {activity.location}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12} className="Content-right">
                    <Link href="https://outdoor-slovenia.regiondo.com/categories?tag=najemi-sobo-si" underline="none" >
                        <Button
                            variant="outlined"
                            color="primary"
                            endIcon={<NavigateNextIcon/>}
                        >
                            { this.props.t("More activities")}
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(Activities)
