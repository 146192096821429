import React from "react"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"

export interface LoaderProps {
    accommodationStore?: AccommodationStore
}

@inject("accommodationStore")
@observer
export default class Loader extends React.Component<LoaderProps> {
    render () {
        // TODO make proper style
        return (
            <Backdrop open={this.props.accommodationStore!.loader} className="Loader">
                <CircularProgress color="primary" />
            </Backdrop>
        )
    }
}
