import React from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Link from "@material-ui/core/Link"

interface FooterProps extends WithTranslation {
}

interface FooterState {
    privacyShow: boolean
    termsShow: boolean
}

class Footer extends React.Component<FooterProps, FooterState> {
    constructor (props: FooterProps) {
        super(props)

        this.state = {
            privacyShow: false,
            termsShow: false
        }
    }

    showPrivacy = (e: React.SyntheticEvent) => {
        e.preventDefault()
        this.setState({
            privacyShow: true
        })
    }

    showTerms = (e: React.SyntheticEvent) => {
        e.preventDefault()
        this.setState({
            termsShow: true
        })
    }

    closePrivacy = () => {
        this.setState({
            privacyShow: false
        })
    }

    closeTerms = () => {
        this.setState({
            termsShow: false
        })
    }

    render () {
        return (
            <Grid container>
                <Grid item xs="auto">
                    &copy; 2020 Snapguest d.o.o.&nbsp;&nbsp;
                    <Link href="#" color="textPrimary" onClick={this.showPrivacy}>{this.props.t("Privacy Policy")}</Link>
                    &nbsp;-&nbsp;
                    <Link href="#" color="textPrimary" onClick={this.showTerms}>{this.props.t("Terms")}</Link>
                </Grid>
                <Dialog onClose={this.closePrivacy} aria-labelledby="simple-dialog-title" open={this.state.privacyShow} maxWidth="sm" fullWidth={true}>
                    <DialogTitle id="simple-dialog-title">{this.props.t("Privacy Policy")}</DialogTitle>
                    <DialogContent>

                    </DialogContent>
                </Dialog>
                <Dialog onClose={this.closeTerms} aria-labelledby="simple-dialog-title" open={this.state.termsShow} maxWidth="sm" fullWidth={true}>
                    <DialogTitle id="simple-dialog-title">{this.props.t("Terms")}</DialogTitle>
                    <DialogContent>

                    </DialogContent>
                </Dialog>
            </Grid>
        )
    }
}

export default withTranslation()(Footer)
