import React from "react"

export interface MapMarkerProps {
    idx: number
    label: any
    lat: number
    lng: number
    selected: boolean
    showPopover: Function
}

export default class MapMarker extends React.Component<MapMarkerProps> {
    onMarkerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.props.showPopover(this.props.idx, event)
    }

    render () {
        var Klasses = [
            "Map-marker",
            this.props.selected ? "Map-marker-selected" : null
        ].filter((klass) => klass).join(" ")

        return (
            <span className={Klasses} onClick={this.onMarkerClick}>
                {this.props.label}
            </span>
        )
    }
}
