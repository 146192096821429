import React from "react"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import { withTranslation, WithTranslation } from "react-i18next"
import { accomodationTypes } from "../utils/Data"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Link from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import SearchIcon from "@material-ui/icons/Search"
import Typography from "@material-ui/core/Typography"
import Pagination from "@material-ui/lab/Pagination"
import Autocomplete from "@material-ui/lab/Autocomplete"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import AccomodationItem from "./AccomodationItem"

interface AccomodationListProps extends WithTranslation {
    accommodationStore?: AccommodationStore
}

@inject("accommodationStore")
@observer
class AccomodationList extends React.Component<AccomodationListProps> {
    private scrollRef = React.createRef<HTMLDivElement>()

    showSearchPopup = (e: React.SyntheticEvent) => {
        e.preventDefault()
        this.props.accommodationStore!.showSearchPopup()
    }

    setAccomodationTypes = (event: React.ChangeEvent<any>, types: string[]) => {
        this.props.accommodationStore!.setFilters({
            accomodationTypes: types
        })
        this.props.accommodationStore!.setPage(1)

        this.refreshAccomodations()
    }

    setAccomodationType = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        const type: string = event.target.value

        this.props.accommodationStore!.setFilters({
            accomodationTypes: type === "" ? [] : [type]
        })
        this.props.accommodationStore!.setPage(1)

        this.refreshAccomodations()
    }

    setPriceSort = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        const priceSort = event.target.value

        this.props.accommodationStore!.setFilters({
            priceSort: priceSort === "" ? null : parseInt(priceSort)
        })
        this.props.accommodationStore!.setPage(1)

        this.refreshAccomodations()
    }

    changePage = (event: React.ChangeEvent<any>, page: number) => {
        this.props.accommodationStore!.setPage(page)

        this.refreshAccomodations()
    }

    refreshAccomodations = () => {
        this.props.accommodationStore!.searchAccomodations(() => {
            const node = this.scrollRef.current
            node!.scrollTop = 0
        })
    }

    render () {
        let type = ""
        if (this.props.accommodationStore!.filters.accomodationTypes.length > 0) {
            type = this.props.accommodationStore!.filters.accomodationTypes[0]
        }

        return (
            <Grid container>
                <Grid item xs={12} className="Accomodation-list-container">
                    <Grid container spacing={2} className="Accomodation-list" alignItems="flex-start" alignContent="flex-start" ref={this.scrollRef}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="h5">
                                        {this.props.t("Accomodations in")}&nbsp;
                                        <Link href="#" onClick={this.showSearchPopup} color="inherit" underline="always">
                                            {this.props.accommodationStore!.searchParams.place}
                                        </Link>
                                    </Typography>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {this.props.accommodationStore!.accomodationCount}&nbsp;{this.props.t("Accomodations")}
                                        &nbsp;&sdot;&nbsp;
                                        <Link href="#" onClick={this.showSearchPopup} color="inherit" underline="always">
                                            {this.props.accommodationStore!.searchDatesFormated}
                                            &nbsp;&sdot;&nbsp;
                                            {this.props.accommodationStore!.searchParams.guests}&nbsp;{this.props.t("Guests")}
                                        </Link>
                                    </Typography>
                                </Grid>
                                <Hidden only="xs">
                                    <Grid item xs={12} sm={6} className="Content-right">
                                        <Button
                                            color="secondary"
                                            startIcon={<SearchIcon />}
                                            onClick={this.showSearchPopup}
                                        >
                                            {this.props.t("Search")}
                                        </Button >
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Hidden mdDown>
                                            <Grid item xs={6} sm={5} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    multiple
                                                    autoHighlight
                                                    openOnFocus
                                                    value={this.props.accommodationStore!.filters.accomodationTypes}
                                                    options={accomodationTypes as string[]}
                                                    getOptionLabel={(type: string) => type}
                                                    onChange={this.setAccomodationTypes}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label={this.props.t("Accomodation type")}
                                                    />}
                                                />
                                            </Grid>
                                        </Hidden>
                                        <Hidden lgUp>
                                            <Grid item xs={6} sm={5} md={4}>
                                                <FormControl variant="outlined" size="small">
                                                    <InputLabel htmlFor="select-accomodation-type">{this.props.t("Accomodation type")}</InputLabel>
                                                    <Select
                                                        native
                                                        value={type}
                                                        onChange={this.setAccomodationType}
                                                        label={this.props.t("Accomodation type")}
                                                        inputProps={{
                                                            name: "type",
                                                            id: "select-accomodation-typee"
                                                        }}
                                                    >
                                                        <option value="" />
                                                        {accomodationTypes.map((accomodationType, idx) => {
                                                            return (
                                                                <option key={idx} value={accomodationType}>{accomodationType}</option>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Hidden>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <FormControl variant="outlined" size="small">
                                                <InputLabel htmlFor="select-sort-price">{this.props.t("Price")}</InputLabel>
                                                <Select
                                                    native
                                                    value={this.props.accommodationStore!.filters.priceSort || ""}
                                                    onChange={this.setPriceSort}
                                                    label={this.props.t("Price")}
                                                    inputProps={{
                                                        name: "price",
                                                        id: "select-sort-price"
                                                    }}
                                                >
                                                    <option value="" />
                                                    <option value={1}>{this.props.t("Ascending")}</option>
                                                    <option value={-1}>{this.props.t("Descending")}</option>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {this.props.accommodationStore!.accomodationList.map((accommodation, idx) => {
                                    if (!(accommodation.podatkiNastanitve && !accommodation.podatkiNastanitve.vidno)) {
                                        return (
                                            <Grid item xs={12} key={accommodation.rnoId}>
                                                <AccomodationItem data={accommodation} selected={this.props.accommodationStore!.accomodationMarked === idx} selectIndex={idx} />
                                            </Grid>)
                                    }
                                    return (null)
                                })}
                                <Grid item xs={12} className="Content-center">
                                    <Pagination
                                        page={this.props.accommodationStore!.page}
                                        count={this.props.accommodationStore!.pageCount}
                                        onChange={this.changePage}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(AccomodationList)
