import React from "react"
import axios from "axios"
import { backendUrl } from "./Config"
import { Obrat, Nastanitev, EditReqData, SearchResponse } from "./Models"

export interface BackendInt {
    getAutocomplete: Function
    searchAccomodations: Function
    isTokenValid:Function
    getAccomodation: Function
    getAccomodationWithData: Function
    putAccomodationData: Function
    getAccomodationEditRequest: Function
    postBookRequest: Function
    getFavoriteAccomodations: Function
}

export default class Backend implements BackendInt {
    getAutocomplete (place: string, limit: string, callback: Function) {
        var config = {
            params: {
                place: place,
                limit: limit
            }
        }

        axios.get(this.autocompleteUrl(), config)
            .then(res => {
                const data: string[] = res.data
                callback(data)
            }).catch(err => {
                callback(null)
                console.error(err)
            })
    }

    searchAccomodations (place: string, guests: number, page: number, filters: any, callback: Function) {
        var config = {
            params: {
                place: place,
                guests: guests,
                page: page - 1,
                accomodationTypes: filters.accomodationTypes,
                priceSort: filters.priceSort
            }
        }

        axios.get(this.searchUrl(), config)
            .then(res => {
                const data: SearchResponse = res.data
                callback(data)
            }).catch(err => {
                callback(null)
                console.error(err)
            })
    }

    isTokenValid (token: string, callback: Function) {
        axios.get(this.validationUrl(token))
            .then(res => {
                const data = res.data
                callback(data.rnoId)
            }).catch(err => {
                callback(null)
                console.error(err)
            })
    }

    getAccomodation (id: string, callback: Function) {
        axios.get(this.accomodationUrl(id))
            .then(res => {
                const accomodation = res.data
                callback(accomodation)
            }).catch(err => {
                console.error(err)
            })
    }

    getAccomodationWithData (id: string, callback: Function) {
        this.getAccomodation(id, (accomodation: Obrat) => {
            axios.get(this.accomodationDataUrl(id))
                .then(res => {
                    accomodation.podatkiNastanitve = res.data

                    callback(accomodation)
                }).catch(err => {
                    console.error(err)
                })
        })
    }

    getAccomodationEditRequest (id: string, send: boolean, callback: Function) {
        var config = {
            params: {
                send: send ? 1 : 0
            }
        }

        axios.get(this.accomodationEditRequestUrl(id), config)
            .then(res => {
                const data: EditReqData = res.data
                callback(data)
            }).catch(err => {
                callback(null)
                console.error(err)
            })
    }

    putAccomodationData (token: string, data: Nastanitev, callback: Function) {
        var saveData = {
            opis: data.opis,
            cenaOd: data.cenaOd,
            cenaDo: data.cenaDo,
            vidno: data.vidno,
            slike: data.slike
        }
        var config = {
            params: {
                token: token
            }
        }
        axios.put(this.accomodationDataUrl(data.rnoId), saveData, config)
            .then(res => {
                const accomodationData = res.data

                callback(accomodationData)
            }).catch(err => {
                console.error(err)
            })
    }

    postBookRequest (rnoId: string, checkIn: string, checkOut: string, guests: number, recaptcha: string, email: string, text: string, callback: Function) {
        var data = {
            checkIn: checkIn,
            checkOut: checkOut,
            guests: guests,
            recaptcha: recaptcha,
            email: email,
            text: text
        }
        axios.post(this.accomodationBookRequestUrl(rnoId), data)
            .then(res => {
                callback()
            }).catch(err => {
                console.error(err)
            })
    }

    getFavoriteAccomodations (callback: Function) {
        axios.get(this.accomodationsFavoritesUrl())
            .then(res => {
                const accomodations = res.data
                callback(accomodations)
            }).catch(err => {
                console.error(err)
            })
    }

    private autocompleteUrl () {
        return backendUrl + "autocomplete"
    }

    private searchUrl () {
        return backendUrl + "search"
    }

    private validationUrl (token: string) {
        return backendUrl + "valid/" + token
    }

    private accomodationUrl (id: string) {
        return backendUrl + "accommodation/" + id
    }

    private accomodationDataUrl (id: string) {
        return this.accomodationUrl(id) + "/data"
    }

    private accomodationEditRequestUrl (id: string) {
        return this.accomodationUrl(id) + "/request"
    }

    private accomodationBookRequestUrl (id: string) {
        return this.accomodationUrl(id) + "/book/request"
    }

    private accomodationsFavoritesUrl () {
        return backendUrl + "accommodations/favorites"
    }
}

const BackendContext = React.createContext<BackendInt | null>(null)

export { BackendContext }
