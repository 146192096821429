import React from "react"
import moment from "moment"
import { Link as RouteLink } from "react-router-dom"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import { withTranslation, WithTranslation } from "react-i18next"
import { Obrat } from "../utils/Models"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import AccomodationItemImages from "../componets/AccomodationItemImages"

export interface AccomodationItemProps extends WithTranslation {
    accommodationStore?: AccommodationStore
    data: Obrat
    selected: boolean
    selectIndex: number
}

@inject("accommodationStore")
@observer
class AccomodationItem extends React.Component<AccomodationItemProps> {
    showEditReqPopup = (e: React.SyntheticEvent) => {
        e.preventDefault()
        this.props.accommodationStore!.showAccomodationEditReq()
    }

    onItemEnter = () => {
        this.props.accommodationStore!.accomodationMarked = this.props.selectIndex
    }

    onItemLeave = () => {
        this.props.accommodationStore!.accomodationMarked = -1
    }

    images = () => {
        var nData = this.props.data.podatkiNastanitve
        if (nData && nData.slike.length > 0) {
            return (
                <AccomodationItemImages images={nData.slike} />
            )
        } else {
            return (
                <Box className="Accomodation-item-images-holder">
                    <Link href="#" onClick={this.showEditReqPopup} color="secondary">
                        {this.props.t("Your accomodation?")}<br/>{this.props.t("Add pictures")}
                    </Link>
                </Box>
            )
        }
    }

    bedsAndUnits = () => {
        const beds = `${this.props.data.steviloStalnihLezisc} ${this.props.t("Beds")}`
        var units: string[] = []
        this.props.data.nedeljiveEnote.forEach((unit: any) => {
            units.push(`${unit.kolicina}x ${unit.vrsta}`)
        })

        return `${beds} (${units.join(", ")})`
    }

    additionalOffers = () => {
        var offers: string[] = []

        this.props.data.dodatnaPonudba.forEach((offer) => {
            offers.push(this.props.t(offer))
        })

        return offers.join(" · ")
    }

    pricePerNight = () => {
        var nData = this.props.data.podatkiNastanitve
        if (nData) {
            if (nData.cenaOd === nData.cenaDo) {
                return `${nData.cenaOd}€ /${this.props.t("Night")}`
            } else {
                return `${nData.cenaOd}€ - ${nData.cenaDo}€ /${this.props.t("Night")}`
            }
        } else {
            return ""
        }
    }

    totalPrice = () => {
        var nData = this.props.data.podatkiNastanitve
        if (nData) {
            var nights = this.props.accommodationStore!.nights
            if (nData.cenaOd === nData.cenaDo) {
                var total = nData.cenaOd * nights
                return `${total}€ ${this.props.t("Total")}`
            } else {
                var totalFrom = nData.cenaOd * nights
                var totalTo = nData.cenaDo * nights
                return `${totalFrom}€ - ${totalTo}€ ${this.props.t("Total")}`
            }
        } else {
            return (
                <Link href="#" onClick={this.showEditReqPopup} color="secondary">
                    {this.props.t("Your accomodation?")}<br/>{this.props.t("Edit prices")}
                </Link>
            )
        }
    }

    detailsLink = () => {
        var params = this.props.accommodationStore!.searchParams
        var from = moment(params.checkIn).format(moment.HTML5_FMT.DATE)
        var to = moment(params.checkOut).format(moment.HTML5_FMT.DATE)

        return `/detail/${this.props.data.rnoId}?from=${from}&to=${to}&guests=${params.guests}`
    }

    render () {
        var Klasses = [
            "Accomodation-item",
            this.props.selected ? "Accomodation-item-selected" : null
        ].filter((klass) => klass).join(" ")

        return (
            <div onMouseEnter={this.onItemEnter} onMouseLeave={this.onItemLeave}>
                <Grid container spacing={2} className={Klasses}>
                    <Grid item xs={12} sm={4}>
                        {this.images()}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    {this.props.data.nazivKategorije}
                                </Typography>
                                <Typography variant="h6">
                                    {this.props.data.nazivNastanitvenegaObrata}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="Accomodation-item-content">
                                <Box>
                                    <Typography variant="caption" color="textSecondary" paragraph>
                                        {this.bedsAndUnits()}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {this.additionalOffers()}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6">
                                            {this.pricePerNight()}
                                        </Typography>
                                        <Typography variant="body2">
                                            {this.totalPrice()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="Content-right">
                                        <RouteLink to={this.detailsLink()} className="Link">
                                            <Button
                                                variant="contained"
                                                color={ this.props.data.rezervacija ? "primary" : "secondary"}
                                            >
                                                { this.props.data.rezervacija ? this.props.t("Book") : this.props.t("Send inquiry")}
                                            </Button>
                                        </RouteLink>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withTranslation()(AccomodationItem)
