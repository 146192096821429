import React from "react"
import moment from "moment"

export interface SemaforumProps {
    heading: string
    price: string
    region: number
    location: string
    keywords: string[]
    dateStart: Date
    dateEnd: Date
    url: string
}

export default class Semaforum extends React.Component<SemaforumProps> {
    getStart = () => {
        return moment(this.props.dateStart).format("DD-MM-YYYY")
    }

    getEnd = () => {
        return moment(this.props.dateEnd).format("DD-MM-YYYY")
    }

    getBody = () => {
        return `Na najemi-sobo.si ponujajo nočitve v "${this.props.heading}" od ${this.getStart()} do ${this.getEnd()} za ${this.props.price}. Meni se zdi zanimivo, bi šel kdo z menoj?`
    }

    getRegion = () => {
        var index = this.props.region / 1000
        switch (index) {
        case 1:
            return "ljubljana"
        case 2:
            return "štajerska"
        case 3:
            return "koroška"
        case 4:
            return "gorenjska"
        case 5:
            return "goriška"
        case 6:
            return "primorska"
        case 8:
            return "dolenjska"
        case 9:
            return "prekmurska"
        default:
            return "ljubljana"
        }
    }

    getUrl = () => {
        var baseUrl = "https://semaforum.si/posts/create_via_get?"

        var params = [
            "post[heading]=" + this.props.heading,
            "post[body]=" + this.getBody(),
            "post[category_id]=137",
            "post[keywords]=" + this.props.keywords.join(","),
            "post[region]=" + this.getRegion(),
            "post[location]=" + this.props.location,
            "post[date_start]=" + this.getStart(),
            "post[date_end]=" + this.getEnd(),
            "post[najemisobosi_url]=" + this.props.url
        ]

        return encodeURI(baseUrl + params.join("&"))
    }

    render () {
        return (
            <div style={{ backgroundColor: "#23A6F1", padding: "6px" }}>
                <a
                    style={{ textDecoration: "none", color: "#FFFFFF", fontWeight: "bold", fontSize: "12pt", textTransform: "uppercase" }}
                    title="S klikom na gumb boš lahko preko platform Semaforum.si našel/našla družbo za to potovanje"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.getUrl()}>
                    <svg
                        viewBox="418.5 75.8 51 185.6"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ height: "17px", margin: "0 5px", position: "relative", top: "3px" }}>
                        <path fill="#F69C00" d="M469.5 168.6c0 14.1-11.4 25.5-25.5 25.5s-25.5-11.4-25.5-25.5 11.4-25.5 25.5-25.5 25.5 11.4 25.5 25.5z"/>
                        <path fill="#00D664" d="M469.5 101.3c0 14.1-11.4 25.5-25.5 25.5s-25.5-11.4-25.5-25.5 11.4-25.5 25.5-25.5 25.5 11.4 25.5 25.5z"/>
                        <path fill="#ED2D30" d="M469.5 235.9c0 14.1-11.4 25.5-25.5 25.5s-25.5-11.4-25.5-25.5 11.4-25.5 25.5-25.5c14.1.1 25.5 11.5 25.5 25.5z"/>
                    </svg>
                    Najdi Družbo
                </a>
            </div>
        )
    }
}
