import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en from "./translations/en.json"
import sl from "./translations/sl.json"

// Translations
const resources = {
    en: {
        translation: en
    },
    sl: {
        translation: sl
    }
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "sl",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    })

export default i18n
