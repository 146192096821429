import React from "react"
import { Slika } from "../utils/Models"
import Grid from "@material-ui/core/Grid"
import CardActionArea from "@material-ui/core/CardActionArea"
import ImagesViewPopup from "../componets/ImagesViewPopup"

export interface DetailImagesProps {
    images: Slika[]
}

export interface DetailImagesState {
    showView: boolean
    showStep: number
}

export default class DetailImages extends React.Component<DetailImagesProps, DetailImagesState> {
    constructor (props: DetailImagesProps) {
        super(props)

        this.state = {
            showView: false,
            showStep: 0
        }
    }

    showImagesView = (step: number) => {
        this.setState({
            showView: true,
            showStep: step
        })
    }

    hideImagesView = () => {
        this.setState({
            showView: false
        })
    }

    render () {
        var smallImages = this.props.images.slice(1, 5)

        return (
            <Grid container spacing={1} className="Detail-images">
                <Grid item xs={12} md={6}>
                    <CardActionArea onClick={() => { this.showImagesView(0) }}>
                        <img src={this.props.images[0].url} alt={this.props.images[0].naslov} className="Detail-images-big" />
                    </CardActionArea>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                        {smallImages.map((img, idx) => (
                            <Grid item xs={12} sm={6} key={idx}>
                                <CardActionArea onClick={() => { this.showImagesView(idx + 1) }}>
                                    <img src={img.url} alt={img.naslov} className="Detail-images-small" />
                                </CardActionArea>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <ImagesViewPopup images={this.props.images} show={this.state.showView} showStep={this.state.showStep} onHide={this.hideImagesView}/>
            </Grid>
        )
    }
}
