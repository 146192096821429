import React from "react"
import {
    RouteComponentProps,
    withRouter
} from "react-router-dom"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import { withTranslation, WithTranslation } from "react-i18next"
import { Obrat } from "../utils/Models"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"

interface FavoritesProps extends WithTranslation, RouteComponentProps {
    accommodationStore?: AccommodationStore
}

interface FavoritesState {
    accomodations: Obrat[]
}

@inject("accommodationStore")
@observer
class Favorites extends React.Component<FavoritesProps, FavoritesState> {
    constructor (props: FavoritesProps) {
        super(props)

        this.state = {
            accomodations: []
        }
    }

    componentDidMount () {
        this.props.accommodationStore!.favoriteAccomodations((accomodations: Obrat[]) => {
            this.setState({
                accomodations: accomodations
            })
        })
    }

    showDetails = (rnoId: string) => {
        this.props.history.push("/detail/" + rnoId)
    }

    render () {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        {this.props.t("Favorite accomodations")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={6}>
                        {this.state.accomodations.map((accomodation, idx) => (
                            <Grid item key={idx} xs={12} sm={6} md={3}>
                                <Card variant="outlined">
                                    <CardActionArea onClick={() => { this.showDetails(accomodation.rnoId) }}>
                                        <CardMedia
                                            component="img"
                                            height={200}
                                            image={accomodation.podatkiNastanitve?.slike[0].url}
                                            title={accomodation.podatkiNastanitve?.slike[0].naslov}
                                        />
                                        <CardContent>
                                            <Typography variant="subtitle2">
                                                {accomodation.nazivNastanitvenegaObrata}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {accomodation.naslov.nazivObcine}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(withRouter(Favorites))
