import React from "react"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import { withTranslation, WithTranslation } from "react-i18next"
import { FirebaseContext } from "../utils/Firebase"
import { BackendContext } from "../utils/Backend"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import SearchBar from "../componets/SearchBar"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/Add"
import Places from "../componets/Places"
import Favorites from "../componets/Favorites"
import Activities from "../componets/Activities"

interface HeaderProps extends WithTranslation {
    accommodationStore?: AccommodationStore
}

@inject("accommodationStore")
@observer
class HomePage extends React.Component<HeaderProps> {
    componentDidMount () {
        document.title = "Najemi sobo"
    }

    showEditReqPopup = () => {
        this.props.accommodationStore!.showAccomodationEditReq()
    }

    render () {
        return (
            <Container maxWidth="lg" className="Home-container">
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <FirebaseContext.Consumer>
                                    {firebase =>
                                        <BackendContext.Consumer>
                                            {backend => <SearchBar firebase={firebase} backend={backend} />}
                                        </BackendContext.Consumer>
                                    }
                                </FirebaseContext.Consumer>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h5">
                                            {this.props.t("Are you accommodation owner?")}
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            {this.props.t("Edit your accomodation data")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="Content-right">
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            onClick={this.showEditReqPopup}
                                            startIcon={<AddIcon />}
                                        >
                                            {this.props.t("Edit your accomodation")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Places />
                    </Grid>
                    <Grid item xs={12}>
                        <Favorites />
                    </Grid>
                    <Grid item xs={12}>
                        <Activities/>
                    </Grid>
                    <Grid item xs={12}></Grid>
                </Grid>
            </Container>
        )
    }
}

export default withTranslation()(HomePage)
