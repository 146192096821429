import React from "react"
import { FirebaseContext } from "../utils/Firebase"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import { BackendContext } from "../utils/Backend"
import Drawer from "@material-ui/core/Drawer"
import Grid from "@material-ui/core/Grid"
import SearchBar from "./SearchBar"

export interface SearchPopupProps {
    accommodationStore?: AccommodationStore
}

@inject("accommodationStore")
@observer
export default class SearchPopup extends React.Component<SearchPopupProps> {
    hidePopup = () => {
        this.props.accommodationStore!.hideSearchPopup()
    }

    render () {
        return (
            <Drawer open={this.props.accommodationStore!.searchPopup} onClose={this.hidePopup} anchor="top">
                <Grid container>
                    <Grid item xs={12} className="Search-bar-popup">
                        <FirebaseContext.Consumer>
                            {firebase =>
                                <BackendContext.Consumer>
                                    {backend => <SearchBar firebase={firebase} backend={backend} />}
                                </BackendContext.Consumer>
                            }
                        </FirebaseContext.Consumer>
                    </Grid>
                </Grid>
            </Drawer>
        )
    }
}
