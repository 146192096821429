import React from "react"
import moment, { Moment } from "moment"
import "moment/locale/sl"
import MomentUtils from "@date-io/moment"
import {
    RouteComponentProps,
    withRouter
} from "react-router-dom"
import { withTranslation, WithTranslation } from "react-i18next"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import { FirebaseInt } from "../utils/Firebase"
import { BackendInt } from "../utils/Backend"
import { Obrat } from "../utils/Models"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import InputAdornment from "@material-ui/core/InputAdornment"
import CircularProgress from "@material-ui/core/CircularProgress"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import SearchIcon from "@material-ui/icons/Search"
import Autocomplete from "@material-ui/lab/Autocomplete"
import NumberInput from "./NumberInput"

interface SearchBarProps extends WithTranslation, RouteComponentProps {
    accommodationStore?: AccommodationStore
    firebase: FirebaseInt | null
    backend: BackendInt | null
}

interface SearchBarState {
    invalidPlace: boolean
    invalidDates: boolean
    showAutocomplete: boolean
    loadingAutocomplete: boolean
    optionsAutocomplete: string[]
    showCheckOut: boolean
}

@inject("accommodationStore")
@observer
class SearchBar extends React.Component<SearchBarProps, SearchBarState> {
    constructor (props: SearchBarProps) {
        super(props)

        this.state = {
            invalidPlace: false,
            invalidDates: false,
            showAutocomplete: false,
            loadingAutocomplete: false,
            optionsAutocomplete: [],
            showCheckOut: false
        }
    }

    validatePlace = () => {
        if (this.props.accommodationStore!.searchParams.place === "") {
            this.setState({ invalidPlace: true })
            return false
        } else {
            this.setState({ invalidPlace: false })
            return true
        }
    }

    validateDates = () => {
        if (this.props.accommodationStore!.searchParams.checkIn >= this.props.accommodationStore!.searchParams.checkOut) {
            this.setState({ invalidDates: true })
        } else {
            this.setState({ invalidDates: false })
        }
    }

    preSetPlace = (event: React.ChangeEvent<any>, value: string) => {
        if (value.length < 3) {
            this.setState({
                showAutocomplete: false
            })
            return false
        }
        if (this.props.accommodationStore!.searchParams.place !== value) {
            this.setState({
                showAutocomplete: true,
                loadingAutocomplete: true
            })
            this.props.backend!.getAutocomplete(value, "", (options: string[]) => {
                this.setState({
                    optionsAutocomplete: options,
                    loadingAutocomplete: false
                })
            })
        }
    }

    setPlace = (event: React.ChangeEvent<any>, place: string | null) => {
        if (place == null) {
            place = ""
        }

        this.props.accommodationStore!.setSearchParams({
            place: place
        })

        this.setState({
            showAutocomplete: false
        })
        this.validatePlace()
    }

    setCheckIn = (mDate: Moment | null) => {
        if (mDate === null) {
            mDate = moment()
        }
        mDate.startOf("day")

        this.props.accommodationStore!.setSearchParams({
            checkIn: mDate.toDate(),
            checkOut: mDate.add(1, "days").toDate()
        })

        setTimeout(() => {
            this.setState({
                showCheckOut: true
            })
        }, 100)
    }

    setCheckOut = (mDate: Moment | null) => {
        if (mDate === null) {
            mDate = moment()
            mDate.add(3, "days")
        }

        this.props.accommodationStore!.setSearchParams({
            checkOut: mDate.startOf("day").toDate()
        })

        this.validateDates()
    }

    setGuests = (guests: number) => {
        this.props.accommodationStore!.setSearchParams({
            guests: guests
        })
    }

    handleSearch = () => {
        if (this.validatePlace()) {
            this.props.accommodationStore!.setPage(1)

            this.props.accommodationStore!.searchAccomodations((accomodations: Obrat[]) => {
                var params = this.props.accommodationStore!.searchParams

                if (accomodations.length === 1 && accomodations[0].nazivNastanitvenegaObrata === params.place) {
                    var from = moment(params.checkIn).format(moment.HTML5_FMT.DATE)
                    var to = moment(params.checkOut).format(moment.HTML5_FMT.DATE)

                    this.props.history.push(`/detail/${accomodations[0].rnoId}?from=${from}&to=${to}&guests=${params.guests}`)
                } else {
                    if (this.props.history.location.pathname !== "/search") {
                        this.props.history.push("/search")
                    }
                }

                this.props.accommodationStore!.hideSearchPopup()

                this.props.firebase!.logEvent("search-results-view", {
                    "search-text": params.place
                })
            })
        }
    }

    render () {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils} locale={this.props.i18n.language}>
                <Grid container spacing={2} className="Search-bar">
                    <Grid item xs={12} sm={4} md={5}>
                        <Autocomplete
                            id="place"
                            fullWidth
                            freeSolo
                            autoHighlight
                            value={this.props.accommodationStore!.searchParams.place}
                            open={this.state.showAutocomplete}
                            options={this.state.optionsAutocomplete}
                            loading={this.state.loadingAutocomplete}
                            getOptionLabel={(place: string) => place}
                            onChange={this.setPlace}
                            onInputChange={this.preSetPlace}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={this.state.invalidPlace}
                                    label={this.props.t("Place")}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {this.state.loadingAutocomplete ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <DatePicker
                            id="checkInDate"
                            autoOk
                            fullWidth
                            disablePast
                            disableToolbar
                            variant="inline"
                            format="l"
                            label={this.props.t("Check-in")}
                            value={this.props.accommodationStore!.searchParams.checkIn}
                            onChange={this.setCheckIn}
                            error={this.state.invalidDates}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CalendarTodayIcon color="secondary" />
                                    </InputAdornment>
                                )
                            }}
                        />

                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <DatePicker
                            id="checkOutDate"
                            autoOk
                            fullWidth
                            disablePast
                            disableToolbar
                            variant="inline"
                            format="l"
                            label={this.props.t("Check-out")}
                            value={this.props.accommodationStore!.searchParams.checkOut}
                            onChange={this.setCheckOut}
                            error={this.state.invalidDates}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CalendarTodayIcon color="secondary" />
                                    </InputAdornment>
                                )
                            }}
                            open={this.state.showCheckOut}
                            onOpen={() => { this.setState({ showCheckOut: true }) }}
                            onClose={() => { this.setState({ showCheckOut: false }) }}
                        />

                    </Grid>
                    <Grid item xs={12} sm={2} md={1}>
                        <NumberInput
                            label={this.props.t("Guests")}
                            defaultValue={this.props.accommodationStore!.searchParams.guests}
                            onChange={this.setGuests}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} className="Content-center">
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={this.state.invalidDates}
                            onClick={this.handleSearch}
                            startIcon={<SearchIcon/>}
                        >
                            {this.props.t("Search")}
                        </Button>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        )
    }
}

export default withTranslation()(withRouter(SearchBar))
