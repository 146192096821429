import React from "react"
import {
    RouteComponentProps,
    withRouter
} from "react-router-dom"
import { FirebaseInt } from "../utils/Firebase"
import { BackendInt } from "../utils/Backend"
import { Obrat, Nastanitev } from "../utils/Models"
import { withTranslation, WithTranslation } from "react-i18next"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Switch from "@material-ui/core/Switch"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Typography from "@material-ui/core/Typography"
import MUIRichTextEditor from "mui-rte"
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js"
import { stateToHTML } from "draft-js-export-html"
import PhotoUploader, { PhotoRaw } from "../componets/PhotoUploader"

interface AdminPageProps extends WithTranslation, RouteComponentProps {
    accommodationStore?: AccommodationStore
    firebase: FirebaseInt | null
    backend: BackendInt | null
}

interface AdminPageState {
    accomodation: Obrat | null
    data: Nastanitev
    photosRaw: PhotoRaw[]
    descText: String
    infoText: String
}

@inject("accommodationStore")
@observer
class AdminPage extends React.Component<AdminPageProps, AdminPageState> {
    constructor (props: AdminPageProps) {
        super(props)

        this.state = {
            accomodation: null,
            data: {
                id: "",
                rnoId: "",
                opis: "",
                cenaOd: 0,
                cenaDo: 0,
                vidno: true,
                slike: []
            },
            photosRaw: [],
            descText: "",
            infoText: ""
        }
    }

    getToken () {
        const search = this.props.location.search
        const params = new URLSearchParams(search)
        const token = params.get("token")

        return token
    }

    componentDidMount () {
        this.props.accommodationStore!.showLoader()

        const token = this.getToken()

        this.props.backend!.isTokenValid(token, (rnoId: string | null) => {
            if (rnoId) {
                this.props.firebase!.logEvent("cms-edit", { "rno-id": rnoId })

                this.props.backend!.getAccomodationWithData(rnoId, (accomodation: Obrat) => {
                    var data = accomodation.podatkiNastanitve

                    this.setState({
                        accomodation: accomodation
                    })

                    if (data !== null) {
                        this.setState({
                            data: data
                        })

                        const contentHTML = convertFromHTML(data.opis)
                        const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
                        const text = JSON.stringify(convertToRaw(state))

                        this.setState({
                            descText: text
                        })
                    } else {
                        data = this.state.data
                        data.rnoId = accomodation.rnoId
                        this.setState({
                            data: data
                        })
                    }
                    this.props.accommodationStore!.hideLoader()
                })
            } else {
                this.props.accommodationStore!.showAccomodationEditReq()
                this.props.accommodationStore!.hideLoader()
            }
        })
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target
        const value: string | boolean = target.name === "vidno" ? target.checked : target.value

        var data = this.state.data

        if (typeof value === "string") {
            const name = target.name as keyof Pick<Nastanitev, "cenaOd" | "cenaDo" >
            data[name] = parseInt(value)
        } else if (typeof value === "boolean") {
            const name = target.name as keyof Pick<Nastanitev, "vidno">
            data[name] = value
        }

        this.setState({
            data: data,
            infoText: ""
        })
    }

    handleDescChange = (state: EditorState) => {
        var data = this.state.data

        data.opis = stateToHTML(state.getCurrentContent())

        this.setState({
            data: data,
            infoText: ""
        })
    }

    handlePhotosRaw = (photos: PhotoRaw[]) => {
        this.setState({
            photosRaw: photos
        })
    }

    save = () => {
        this.props.accommodationStore!.showLoader()
        this.setState({
            infoText: ""
        })

        const token = this.getToken()

        this.props.backend!.isTokenValid(token, (rnoId: string | null) => {
            if (rnoId === null) {
                this.setState({
                    infoText: this.props.t("Token invalid")
                })
                this.props.accommodationStore!.hideLoader()
                this.props.accommodationStore!.showAccomodationEditReq()
            } else {
                this.props.firebase!.logEvent("cms-edit-save", { "rno-id": rnoId })

                var photosRaw = this.state.photosRaw
                var data = this.state.data

                var promises: any[] = []

                photosRaw.forEach((photoRaw: PhotoRaw, index: number) => {
                    if (photoRaw.img === null) {
                        promises.push({
                            url: photoRaw.url,
                            naslov: photoRaw.title,
                            zapSt: index
                        })
                    } else {
                        promises.push(this.props.firebase!.uploadPhoto(index, photoRaw))
                    }
                })

                Promise.all(promises).then((slike) => {
                    data.slike = slike

                    this.props.backend!.putAccomodationData(token, data, (data: Nastanitev) => {
                        this.setState({
                            infoText: this.props.t("Data saved")
                        })
                        this.props.accommodationStore!.hideLoader()
                    })
                })
            }
        })
    }

    render () {
        return (
            <Grid container className="Admin">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {this.state.accomodation?.nazivNastanitvenegaObrata}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <PhotoUploader
                            photos={this.state.data.slike}
                            onChange={this.handlePhotosRaw}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.data.vidno}
                                            onChange={this.handleInputChange}
                                            name="vidno"
                                            color="primary"
                                        />
                                    }
                                    label={this.props.t("Publish to Najemi sobo web portal")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="cenaOd"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    inputProps={{
                                        min: 0
                                    }}
                                    label={this.props.t("Price from")}
                                    value={this.state.data.cenaOd}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="cenaDo"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    inputProps={{
                                        min: 0
                                    }}
                                    label={this.props.t("Price to")}
                                    value={this.state.data.cenaDo}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel shrink={true}>{this.props.t("Description")}</InputLabel>
                                    <Box className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                        <MUIRichTextEditor
                                            controls={["undo", "redo", "title", "bold", "italic", "underline"]}
                                            label={this.props.t("Enter description...")}
                                            defaultValue={this.state.descText}
                                            onChange={this.handleDescChange}
                                        />
                                    </Box>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.save}
                                >
                                    {this.props.t("Save")}
                                </Button>
                            &nbsp;
                                <Typography variant="body2" display="inline">
                                    {this.state.infoText}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(withRouter(AdminPage))
