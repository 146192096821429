import React from "react"
import firebase from "firebase/app"
import "firebase/storage"
import "firebase/analytics"
import { v4 as uuidv4 } from "uuid"
import { firebaseConfig } from "./Config"
import { PhotoRaw } from "../componets/PhotoUploader"

export interface FirebaseInt {
    uploadPhoto: Function;
    logEvent: Function;
}

export default class Firebase implements FirebaseInt {
    private storage: firebase.storage.Storage
    private analytics: firebase.analytics.Analytics

    constructor () {
        var app = firebase.initializeApp(firebaseConfig)

        this.storage = app.storage()
        this.analytics = app.analytics()
    }

    uploadPhoto (index: number, photoRaw: PhotoRaw) {
        var storageRef = this.storage.ref()
        var userPhotoRef = storageRef.child("images/accomodations/" + uuidv4() + ".jpg")

        var newMetadata = {
            cacheControl: "public,max-age=15552000"
        }

        var promise = userPhotoRef.put(photoRaw.img!, newMetadata).then((snapshot) => {
            return snapshot.ref.getDownloadURL()
        }).then((photoUrl) => {
            return {
                url: photoUrl,
                naslov: photoRaw.title,
                zapSt: index
            }
        })

        return promise
    }

    logEvent (name: string, params?: { [key: string]: any }) {
        this.analytics.logEvent(name, params)
    }
}

const FirebaseContext = React.createContext<FirebaseInt | null>(null)

export { FirebaseContext }
