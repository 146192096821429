import React from "react"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"

export interface NumberInputProps {
    label: string
    defaultValue: number
    onChange: (newValue: number) => void;
}

export interface NumberInputState {
    value: number
}

export default class NumberInput extends React.Component<NumberInputProps, NumberInputState> {
    constructor (props: NumberInputProps) {
        super(props)

        this.state = {
            value: props.defaultValue
        }
    }

    decreaseNumber = () => {
        this.setState({
            value: this.state.value - 1
        }, () => { this.props.onChange(this.state.value) })
    }

    increaseNumber = () => {
        this.setState({
            value: this.state.value + 1
        }, () => { this.props.onChange(this.state.value) })
    }

    render () {
        return (
            <FormControl>
                <InputLabel shrink={true}>{this.props.label}</InputLabel>
                <Box className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                    <IconButton
                        size="small"
                        disabled={this.state.value === 1}
                        onClick={this.decreaseNumber}
                    ><RemoveCircleOutlineIcon/></IconButton>
                    <span className="Number-input-value">{this.state.value}</span>
                    <IconButton
                        size="small"
                        onClick={this.increaseNumber}
                    ><AddCircleOutlineIcon/></IconButton>
                </Box>
            </FormControl>
        )
    }
}
