import React from "react"
import { Slika } from "../utils/Models"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import MobileStepper from "@material-ui/core/MobileStepper"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"

export interface AccomodationItemImagesProps {
    images: Slika[]
}

export interface AccomodationItemImagesStat {
    activeStep: number
}

export default class AccomodationItemImages extends React.Component<AccomodationItemImagesProps, AccomodationItemImagesStat> {
    constructor (props: AccomodationItemImagesProps) {
        super(props)

        this.state = {
            activeStep: 0
        }
    }

    handleNext = () => {
        this.setActiveStep(this.state.activeStep + 1)
    };

    handleBack = () => {
        this.setActiveStep(this.state.activeStep - 1)
    };

    setActiveStep = (step: number) => {
        this.setState({
            activeStep: step
        })
    }

    render () {
        var maxSteps = this.props.images.length
        return (
            <Box className="Accomodation-item-images">
                <Box className="Accomodation-item-images-wrapper">
                    <img
                        src={this.props.images[this.state.activeStep].url}
                        alt={this.props.images[this.state.activeStep].naslov}
                        className="Accomodation-item-images-img">
                    </img>
                </Box>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={this.state.activeStep}
                    nextButton={
                        <IconButton size="small" onClick={this.handleNext} disabled={this.state.activeStep === maxSteps - 1}>
                            <KeyboardArrowRight />
                        </IconButton >
                    }
                    backButton={
                        <IconButton size="small" onClick={this.handleBack} disabled={this.state.activeStep === 0}>
                            <KeyboardArrowLeft />
                        </IconButton >
                    }
                />
            </Box>
        )
    }
}
