import React from "react"
import "./styles/App.scss"
import { ThemeProvider } from "@material-ui/core/styles"
import createSnapguestTheme from "./styles/snapguestTheme"
import {
    ProtectedRoute,
    ProtectedRouteProps
} from "./utils/ProtectedRoute"
import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom"
import { Provider } from "mobx-react"
import Firebase, { FirebaseContext } from "./utils/Firebase"
import Backend, { BackendContext } from "./utils/Backend"
import AccommodationStore from "./stores/AccommodationStore"
import Box from "@material-ui/core/Box"

import HomePage from "./pages/Home"
import SearchPage from "./pages/Search"
import DetailPage from "./pages/Detail"
import AdminPage from "./pages/Admin"
import Header from "./componets/Header"
import Footer from "./componets/Footer"

import EditRequestPopup from "./componets/EditRequestPopup"
import Loader from "./componets/Loader"

const theme = createSnapguestTheme()

function App () {
    var backend = new Backend()
    var accommodationStore = new AccommodationStore(backend)

    const isAuthenticated = (props: any) => {
        /* const search = props.location.search
        const params = new URLSearchParams(search)
        const token = params.get("token") */

        // TODO put back
        // return token !== null
        return true
    }

    const defaultProtectedRouteProps: ProtectedRouteProps = {
        isAuthenticated: isAuthenticated,
        authenticationPath: "/"
    }

    return (
        <Box className="App">
            <Provider accommodationStore={accommodationStore}>
                <BackendContext.Provider value={backend}>
                    <ThemeProvider theme={theme}>
                        <Loader/>
                        <header className="App-header">
                            <Header/>
                        </header>
                        <Box className="App-body">
                            <FirebaseContext.Provider value={new Firebase()}>
                                <Router>
                                    <Switch>
                                        <Route path="/search">
                                            <SearchPage/>
                                        </Route>
                                        <Route path="/detail/:detailId">
                                            <FirebaseContext.Consumer>
                                                {firebase =>
                                                    <DetailPage firebase={firebase} />
                                                }
                                            </FirebaseContext.Consumer>
                                        </Route>
                                        <ProtectedRoute {...defaultProtectedRouteProps} path="/admin">
                                            <FirebaseContext.Consumer>
                                                {firebase =>
                                                    <BackendContext.Consumer>
                                                        {backend => <AdminPage firebase={firebase} backend={backend} />}
                                                    </BackendContext.Consumer>
                                                }
                                            </FirebaseContext.Consumer>
                                        </ProtectedRoute>
                                        <Route path="/">
                                            <HomePage/>
                                        </Route>
                                    </Switch>
                                </Router>
                                <FirebaseContext.Consumer>
                                    {firebase =>
                                        <BackendContext.Consumer>
                                            {backend => <EditRequestPopup firebase={firebase} backend={backend} />}
                                        </BackendContext.Consumer>
                                    }
                                </FirebaseContext.Consumer>
                            </FirebaseContext.Provider>
                        </Box>
                        <footer className="App-footer">
                            <Footer/>
                        </footer>
                    </ThemeProvider>
                </BackendContext.Provider>
            </Provider>
        </Box>
    )
}

export default App
