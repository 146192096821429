import moment from "moment"
import { observable, action, computed, runInAction } from "mobx"
import { Obrat, MarkerData, SearchResponse } from "../utils/Models"
import { BackendInt } from "../utils/Backend"

interface SearchParams {
    place: string
    checkIn: Date
    checkOut: Date
    guests: number
}

interface Filters {
    accomodationTypes: string[]
    priceSort: number | null
}

class AccomodationStore {
    constructor (backend: BackendInt) {
        this.backend = backend
    }

    backend: BackendInt

    @observable page: number = 1
    @observable pageCount: number = 1

    @observable accomodationCount: number = 0
    @observable accomodationMarked: number = -1
    @observable accomodationList: Array<Obrat> = []
    @observable accomodation: Obrat | null = null

    @observable searchPopup: boolean = false
    @observable searchParams: SearchParams = {
        place: "",
        checkIn: moment().toDate(),
        checkOut: moment().add(3, "days").toDate(),
        guests: 2
    }

    @observable filters: Filters = {
        accomodationTypes: [],
        priceSort: null
    }

    @observable accomodationEditReq: boolean = false
    @observable loader: boolean = false

    @action
    searchAccomodations (callback: Function) {
        this.showLoader()

        this.backend.searchAccomodations(this.searchParams.place, this.searchParams.guests, this.page, this.filters, (data: SearchResponse) => {
            runInAction(() => {
                if (data !== null) {
                    this.pageCount = data.pagescount
                    this.accomodationCount = data.count
                    this.accomodationList = data.items

                    callback(data.items)
                }
                this.hideLoader()
            })
        })
    }

    @action
    favoriteAccomodations (callback: Function) {
        this.backend.getFavoriteAccomodations((accomodations: Obrat[]) => {
            runInAction(() => {
                this.accomodationList = accomodations
                callback(accomodations)
            })
        })
    }

    @action
    getAccomodation (rnoId: string, callback: Function) {
        this.showLoader()

        var accomodation = this.accomodationList.find((a) => a.rnoId === rnoId)

        if (!accomodation) {
            this.backend.getAccomodationWithData(rnoId, (accomodation: Obrat) => {
                runInAction(() => {
                    this.accomodation = accomodation

                    callback()

                    this.hideLoader()
                })
            })
        } else {
            this.accomodation = accomodation

            callback()

            this.hideLoader()
        }
    }

    // Filters
    @action
    setPage (page: number) {
        this.page = page
    }

    @action
    setFilters (filters: Partial<Filters>) {
        this.filters = { ...this.filters, ...filters }
    }

    // Map
    @computed get
    accomodationMarkers () {
        var markers: MarkerData[] = []
        this.accomodationList.forEach((accomodation) => {
            var label = accomodation.podatkiNastanitve ? accomodation.podatkiNastanitve.cenaOd + "€" : accomodation.nazivNastanitvenegaObrata.charAt(0)

            if (accomodation.naslov.x && accomodation.naslov.y) {
                markers.push({
                    label: label,
                    x: accomodation.naslov.x,
                    y: accomodation.naslov.y
                })
            }
        })
        return markers
    }

    @computed get
    middleCoordinates () {
        var center = {
            x: 14.50513,
            y: 46.05108
        }

        if (this.accomodationList.length === 0) {
            return center
        }

        var xs: number[] = []
        var ys: number[] = []
        this.accomodationList.forEach((accomodation) => {
            if (accomodation.naslov.x) {
                xs.push(accomodation.naslov.x)
            }
            if (accomodation.naslov.y) {
                ys.push(accomodation.naslov.y)
            }
        })

        if (xs.length === 0 || ys.length === 0) {
            return center
        }

        xs.sort()
        ys.sort()

        center.x = (xs[0] + xs[xs.length - 1]) / 2
        center.y = (ys[0] + ys[ys.length - 1]) / 2

        return center
    }

    // Search params
    @action
    setSearchParams (params: Partial<SearchParams>) {
        this.searchParams = { ...this.searchParams, ...params }
    }

    @computed get
    searchDatesFormated () {
        var from = moment(this.searchParams.checkIn)
        var to = moment(this.searchParams.checkOut)
        return from.format("D. MMM") + " - " + to.format("D. MMM")
    }

    @computed get
    nights () {
        var from = moment(this.searchParams.checkIn)
        var to = moment(this.searchParams.checkOut)
        return to.diff(from, "days")
    }

    @action
    showSearchPopup () {
        this.searchPopup = true
    }

    @action
    hideSearchPopup () {
        this.searchPopup = false
    }

    // Edit request popup
    @action
    showAccomodationEditReq () {
        this.accomodationEditReq = true
    }

    @action
    hideAccomodationEditReq () {
        this.accomodationEditReq = false
    }

    // Loader
    @action
    showLoader () {
        this.loader = true
    }

    @action
    hideLoader () {
        this.loader = false
    }
}

export default AccomodationStore
