import { createMuiTheme } from "@material-ui/core/styles"

export default function createSnapguestTheme () {
    const defaultTheme = createMuiTheme({
        palette: {
            primary: {
                main: "#0874D8"
            },
            secondary: {
                main: "#777777",
                dark: "#424242"
            }
        },
        overrides: {
            MuiButton: {
                root: {
                    borderRadius: "40px",
                    color: "white"
                },
                textSecondary: {
                    backgroundColor: "#EEEEEE"
                },
                containedSecondary: {
                    color: "white"
                }
            },
            MuiContainer: {
                root: {
                    paddingLeft: "8px",
                    paddingRight: "8px"
                }
            },
            MuiCard: {
                root: {
                    borderRadius: "15px",
                    borderColor: "rgba(0, 0, 0, 0.24)"
                }
            },
            MuiCardActionArea: {
                root: {
                    borderRadius: "15px",
                    borderColor: "rgba(0, 0, 0, 0.24)"
                }
            },
            MuiMobileStepper: {
                root: {
                    backgroundColor: "#ffffff",
                    color: "rgba(0, 0, 0, 0.54)"
                }
            },
            MuiPopover: {
                paper: {
                    borderRadius: "15px",
                    padding: "0.5rem"
                }
            },
            MuiPaper: {
                rounded: {
                    borderRadius: "15px"
                }
            },
            MuiOutlinedInput: {
                root: {
                    borderRadius: "15px"
                }
            },
            MuiSelect: {
                select: {
                    "&:focus": {
                        backgroundColor: "#ffffff",
                    }
                }
            }
        }
    })

    Object.assign(defaultTheme.overrides, {
        MUIRichTextEditor: {
            root: {
                width: "100%"
            },
            editor: {
                minHeight: "14rem",
                padding: "0.8rem",
                border: "1px solid rgba(0, 0, 0, 0.24)",
                borderRadius: "15px"
            },
            placeHolder: {
                padding: "0.8rem"
            }
        }
    })

    return defaultTheme
}
