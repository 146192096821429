import React from "react"
import {
    RouteComponentProps,
    withRouter
} from "react-router-dom"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import { withTranslation, WithTranslation } from "react-i18next"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardActions from "@material-ui/core/CardActions"
import CardActionArea from "@material-ui/core/CardActionArea"

const places = [
    {
        imgPath: "https://firebasestorage.googleapis.com/v0/b/snapguest-booking-site.appspot.com/o/images%2Fplaces%2Fbohinj.jpg?alt=media&token=59d69ff0-13a3-49dd-99e6-1c6b7b0bf554",
        name: "Bohinj"
    },
    {
        imgPath: "https://firebasestorage.googleapis.com/v0/b/snapguest-booking-site.appspot.com/o/images%2Fplaces%2Fljubljana.jpg?alt=media&token=96585988-19a3-4dfa-9dc2-3ad0bd76be5c",
        name: "Ljubljana"
    },
    {
        imgPath: "https://firebasestorage.googleapis.com/v0/b/snapguest-booking-site.appspot.com/o/images%2Fplaces%2Fbled.jpg?alt=media&token=526f6367-5c17-494b-8992-2a3c7bbb465a",
        name: "Bled"
    },
    {
        imgPath: "https://firebasestorage.googleapis.com/v0/b/snapguest-booking-site.appspot.com/o/images%2Fplaces%2Fpiran.jpg?alt=media&token=d0ca412d-6e47-4019-90e1-df7b377e70f7",
        name: "Piran"
    },
    {
        imgPath: "https://firebasestorage.googleapis.com/v0/b/snapguest-booking-site.appspot.com/o/images%2Fplaces%2Fmaribor.jpg?alt=media&token=68ce06ea-ce5d-4954-a160-526bdae08479",
        name: "Maribor"
    },
    {
        imgPath: "https://firebasestorage.googleapis.com/v0/b/snapguest-booking-site.appspot.com/o/images%2Fplaces%2Fvelika_planina.jpg?alt=media&token=ceb3ef71-f498-46c0-b848-31401f7a3a64",
        name: "Kamnik"
    }
]

interface PlacesProps extends WithTranslation, RouteComponentProps {
    accommodationStore?: AccommodationStore
}

@inject("accommodationStore")
@observer
class Places extends React.Component<PlacesProps> {
    showPlaceSearch = (place: string) => {
        this.props.accommodationStore!.setSearchParams({
            place: place
        })

        this.props.accommodationStore!.setPage(1)

        this.props.accommodationStore!.searchAccomodations(() => {
            this.props.history.push("/search")
        })
    }

    render () {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        {this.props.t("Accomodations by favorite destinations")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={6}>
                        {places.map((place, idx) => (
                            <Grid item key={idx} xs={12} sm={6} md={4}>
                                <Card variant="outlined">
                                    <CardActionArea onClick={() => { this.showPlaceSearch(place.name) }}>
                                        <CardMedia
                                            component="img"
                                            height={300}
                                            image={place.imgPath}
                                            title={place.name}
                                        />
                                        <CardActions>
                                            <Typography variant="subtitle1">
                                                {place.name}
                                            </Typography>
                                        </CardActions>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(withRouter(Places))
