import React from "react"
import moment from "moment"
import { FirebaseInt } from "../utils/Firebase"
import { BackendInt } from "../utils/Backend"
import { observer, inject } from "mobx-react"
import AccommodationStore from "../stores/AccommodationStore"
import { withTranslation, WithTranslation } from "react-i18next"
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"

export interface BookRequestProps extends WithTranslation {
    accommodationStore?: AccommodationStore
    firebase: FirebaseInt | null
    backend: BackendInt | null
    rnoId: string
    pricePerNight: string
    totalPrice: string
}

interface BookRequestState {
    email: string
    emailError: boolean
    text: string
    textError: boolean
    recaptchaToken: string | null
    disabled: boolean
}

@inject("accommodationStore")
@observer
class BookRequest extends React.Component<BookRequestProps, BookRequestState> {
    constructor (props: BookRequestProps) {
        super(props)

        this.state = {
            email: "",
            emailError: false,
            text: "",
            textError: false,
            recaptchaToken: null,
            disabled: false
        }
    }

    inputsValid = () => {
        var emailValid = this.state.email.length > 1 && this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        var textValid = this.state.text.length > 10

        this.setState({
            emailError: !emailValid,
            textError: !textValid
        })

        return emailValid && textValid
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target
        const value: string = target.value

        const name = target.name as keyof Pick<BookRequestState, "email" | "text" >

        var data = { ...this.state }
        data[name] = value

        const nameError = (target.name + "Error") as keyof Pick<BookRequestState, "emailError" | "textError" >

        data[nameError] = false

        this.setState(data)
    }

    sendBookRequest = () => {
        if (this.inputsValid() && this.state.recaptchaToken !== null) {
            this.props.accommodationStore!.showLoader()

            var checkIn = moment(this.props.accommodationStore!.searchParams.checkIn).format("Y-MM-DD")
            var checkOut = moment(this.props.accommodationStore!.searchParams.checkOut).format("Y-MM-DD")
            var guests = this.props.accommodationStore!.searchParams.guests

            var recaptcha = this.state.recaptchaToken
            var email = this.state.email
            var text = this.state.text

            this.props.firebase!.logEvent("inquiry-submit", {
                "rno-id": this.props.rnoId
            })

            this.props.backend!.postBookRequest(this.props.rnoId, checkIn, checkOut, guests, recaptcha, email, text, () => {
                this.setState({
                    email: "",
                    emailError: false,
                    text: "",
                    textError: false,
                    recaptchaToken: null,
                    disabled: true
                })
                this.props.accommodationStore!.hideLoader()
            })
        }
    }

    render () {
        return (
            <Grid container className="Detail-book-request">
                <GoogleReCaptchaProvider
                    reCaptchaKey="6LcBma4ZAAAAABTPa6athN8m9bHbJrhLws1DzRpr"
                >
                    <Grid container className="Detail-container-section" spacing={1}>
                        <Grid item xs={6} sm={3}>
                            <Typography variant="overline" color="textSecondary">{this.props.t("Check-in")}</Typography>
                            <Typography variant="subtitle1">{moment(this.props.accommodationStore!.searchParams.checkIn).format("Y-MM-DD")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant="overline" color="textSecondary">{this.props.t("Check-out")}</Typography>
                            <Typography variant="subtitle1">{moment(this.props.accommodationStore!.searchParams.checkOut).format("Y-MM-DD")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <Typography variant="overline" color="textSecondary">{this.props.t("Guests")}</Typography>
                            <Typography variant="subtitle1">{this.props.accommodationStore!.searchParams.guests}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Typography variant="overline" color="textSecondary">{this.props.t("Price")}</Typography>
                            <Typography variant="subtitle1">
                                {this.props.pricePerNight}
                            </Typography>
                            <Typography variant="body2">
                                {this.props.totalPrice}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className="Book-request-form" spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="bookEmail"
                                name="email"
                                fullWidth
                                label={this.props.t("Email")}
                                type="email"
                                required
                                value={this.state.email}
                                onChange={this.handleInputChange}
                                error={this.state.emailError}
                                disabled={this.state.disabled}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="bookText"
                                name="text"
                                fullWidth
                                label={this.props.t("Text")}
                                multiline
                                rows={6}
                                required
                                value={this.state.text}
                                onChange={this.handleInputChange}
                                error={this.state.textError}
                                disabled={this.state.disabled}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GoogleReCaptcha onVerify={token => this.setState({ recaptchaToken: token })} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary">
                                {this.props.t("Book request info")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className="Content-right">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.sendBookRequest}
                                disabled={this.state.disabled}
                            >
                                {this.props.t("Reguest book")}
                            </Button>
                        </Grid>
                    </Grid>
                </GoogleReCaptchaProvider>
            </Grid>
        )
    }
}

export default withTranslation()(BookRequest)
